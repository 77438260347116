<template>
	<div class="">
		<div class="gg-container">
			<wMedicine></wMedicine>
		</div>
	</div>
</template>
<script>
	import wMedicine from "./wMedicine2.0";

	export default {
		name: "drugsList",
		data() {
			return {
				activeName: 'first',
			};
		},
		components: {
			wMedicine

		},
		methods: {

		}
	}
</script>

<style scoped lang="scss">

</style>
