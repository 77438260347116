import request from '@/utils/request'
import {getToken} from "@/utils/auth";
//药品列表
export function getWeihuDrugList(query) { 
    return request({
        url: '/admin/drugManage/getDrugList',
        method: 'get',
        params: query
    }) 
}


//成药列表
export function getDrugList(query) {
    return request({
        url: '/admin/west-drugs',
        method: 'get',
        params: query
    })
}


//中药列表
export function getTCDrugList(query) {
    return request({
        url: '/admin/tc-drugs',
        method: 'get',
        params: query
    })
}


//药品详情
export function wDrugDetail(query) {
    return request({
        url: '/admin/west-drugs/'+query.id,
        method: 'get',
        params: query
    })
}
//中药药详情
export function tcDrugDetail(query) {
    return request({
        url: '/admin/tc-drugs/'+query.id,
        method: 'get',
        params: query
    })
}


//成药编辑
export function editWDrug(query) {
    return request({
        url: '/admin/west-drugs/'+query.id,
        method: 'put',
        data: query
    })
}

//中药编辑
export function editTCWDrug(query) {
    return request({
        url: '/admin/tc-drugs/'+query.id,
        method: 'put',
        data: query
    })
}


//禁用启用  /admin/drugs/7385/enable-status
//'启用状态 1启用 2禁用',

export function enableDrug(query) {
    return request({
        url: '/admin/drugs/'+query.id+'/enable-status',
        method: 'put',
        data: query
    })
}

// 中药删除 : DELTED {{host}}/admin/tc-drugs/7365
// 成药删除 : DELTED {{host}}/admin/west-drugs/7365

//删除药品成药
export function delDrug(query) {
    return request({
        url: '/admin/drugs/'+query.id,
        method: 'delete',
        data: query
    })
}

// 成药导入  /admin/west-drugs-import
export function westDrugsImport(data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization' : 'Bearer '+ getToken()
        },
        url: '/admin/west-drugs-import',
        method: 'post',
        data: data
    })
}

/**
 * 商品导入
 */
export function goodsImport(data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization' : 'Bearer '+ getToken()
        },
        url: '/admin/goods-import',
        method: 'post',
        data: data
    })
}

// 重要药导入  /admin/tc-drugs-import
export function tcDrugsImport(data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization' : 'Bearer '+ getToken()
        },
        url: '/admin/tc-drugs-import',
        method: 'post',
        data: data
    })
}


//商品列表
export function getGoodsList(query) {
    return request({
        url: '/admin/goods',
        method: 'get',
        params: query
    })
}

//商品编辑
export function editGoods(query) {
    return request({
        url: '/admin/goods/'+query.id,
        method: 'put',
        data: query
    })
}

//商品详情
export function goodsDetail(query) {
    return request({
        url: '/admin/goods/'+query.id,
        method: 'get',
        params: query
    })
}

//商户商品详情
export function drugInfo(id) {
    return request({
        url: `/admin/pharmacy-drugs/${id}/drugInfo`,
        method: 'get',
    })
}

export function editPharDrug(id,data) {
    return request({
        url: `/admin/pharmacy-drugs/${id}/editPharDrug`,
        method: 'put',
        data
    })
}
export function delTcmDrug(id) {
    return request({
        url: `/admin/drug/delTcDrug/${id}`,
        method: 'delete',
    })
}

export function statusTcmDrug(id,status) {
    return request({
        url: `/admin/drug/statusTcmDrug/${id}`,
        method: 'put',
        data:{status}
    })
}

export function getTcmDrugs(params) {
    return request({
        url: `/admin/drug/tcmDrugs`,
        method: 'get',
        params
    })
}










